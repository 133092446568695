import {
    Button,
} from 'antd';
import {
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles({
    container: {
        textAlign: 'center',
    }
});

export function Donate() {
    const styles = useStyles();

    return (
        <>
            <h1>Direct Donations</h1>
            <div className={ styles.container }>
                <p>
                    If you're unable to register for the event and would still like to contribute (or if you would like to make an additional contribution), you can find us on Venmo!
                </p>
                <Button href="https://venmo.com/code?user_id=3327287172792320610" target="_blank" rel="noopener noreferrer">
                    Donate via Venmo
                </Button>
            </div>

            <br />

            <p>
                <strong>Note: </strong>
                All donations are handled by The Nora Mae Budge Foundation, which is a 501(c)(3) non-profit.
            </p>
        </>
    )
}