import {
	makeStyles,
} from '@material-ui/core';
import {
	Carousel,
	Button,
} from 'antd';
import {
	Link,
} from 'react-router-dom';
import { ReactComponent as ForeNoraLogo } from '../../icons/fore-nora-logo.svg';

const useStyles = makeStyles({
	picture: {
		height: 450,
		margin: '0 auto',
	},
	story: {
		paddingTop: 30,
		margin: '0 auto',
		maxWidth: 600,
	},
	ctaContainer: {
		textAlign: 'center',
		padding: '30px 0',
	},
	logo: {
		margin: '0 auto',
		width: '80%',
		maxWidth: 200,
		paddingTop: 20,
	}
});

export function NorasStory() {
	const styles = useStyles();

	return (
		<>
			<h1>Nora's Story</h1>
			<Carousel autoplay={ true } autoplaySpeed={ 3000 } effect="fade">
				<div>
					<img className={ styles.picture } alt="nora" src="https://i.imgur.com/5U6y5mz.jpg" />
				</div>
				<div>
					<img className={ styles.picture } alt="nora" src="https://i.imgur.com/W90mQpY.jpg" />
				</div>
				<div>
					<img className={ styles.picture } alt="nora" src="https://i.imgur.com/eDXTgJA.jpg" />
				</div>
				<div>
					<img className={ styles.picture } alt="nora" src="https://i.imgur.com/j2nnHBS.jpg" />
				</div>
				<div>
					<img className={ styles.picture } alt="nora" src="https://i.imgur.com/0GYUK2q.jpg" />
				</div>
			</Carousel>

			<div className={ styles.story }>
				<p>
					Nora Mae Budge was born on June 25, 2020 with multiple heart complications.
					Both her left and right ventricles had difficulty squeezing tightly enough to efficiently circulate blood throughout her body, and she also had holes in both her atrial and ventricular septa.
					In short, even prior to her birth, it was known that Nora would be in need of a new heart as soon as possible.
				</p>
				<p>
					5 days after her birth, Nora had her first (of several) open-heart surgeries, in which a ventricular assist device (VAD) known as the "Berlin Heart" was placed.
					This device would serve as an external, artificial heart in order to pump her blood sufficiently enough to keep her alive long enough to wait for a heart transplant.
					On November 5, 2020, after more than 4 months of waiting and suffering through several complications, Nora received a new heart from a selfless donor.
				</p>
				<p>
					Unfortunately, Nora came out of the heart transplantation surgery with both of her kidneys failing.
					Her kidneys never recovered, and after dialysis stopped working, Nora passed away on December 18, 2020, at nearly 6 months of age.
				</p>
				<p>
					Nora's story is only one of countless similar stories that children, their parents, and their families live through every day.
					This puts a mental, emotional, and financial strain on these families that very few can begin to understand.
					That is why this event is focused on providing resources to these children, their families, and the caregivers who work miracles to give kids a chance at life that they otherwise wouldn't have.
				</p>
				<p>
					Thank you for joining us in honoring Nora's life by helping these beautiful children and their families!
				</p>
			</div>

			<div className={ styles.ctaContainer }>
				<Button type="primary" size="large">
					<Link to="/register">
						Register Here
					</Link>
				</Button>
			</div>

			<div className={ styles.logo }>
				<ForeNoraLogo />
			</div>
		</>
	)
}
