import React from 'react';
import ReactDOM from 'react-dom';
import { ForeNora } from './ForeNora';

import './custom-theme.css';

ReactDOM.render(
  <React.StrictMode>
    <ForeNora />
  </React.StrictMode>,
  document.getElementById('root')
);
