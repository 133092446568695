import {
	useState,
	useCallback,
} from 'react';
import {
	makeStyles,
} from '@material-ui/core/styles';
import {
	Affix,
	Button,
	Drawer,
} from 'antd';
import {
	MenuOutlined,
} from '@ant-design/icons';
import {
	Link,
} from 'react-router-dom';
import { ReactComponent as ForeNoraLogo } from '../../icons/fore-nora-logo.svg';

const useStyles = makeStyles((theme) => ({
	iconDesktop: {
		height: '50px',
		width: '50px',
	},
	iconMobile: {
		height: '50px',
		width: '50px',
	},
	desktopNavContent: {
		backgroundColor: '#fff',
		borderBottom: '1px solid #9a9a9a',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		padding: '10px 0',
	},
	mobileNavContent: {
		backgroundColor: '#fff',
		borderBottom: '1px solid #9a9a9a',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '10px 0',
	},
	mobileLinksContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	link: {
		margin: '0 10px',
	},
	mobileLink: {
		marginBottom: '10px',
	},
	desktopNav: {
		[theme.breakpoints.only('xs')]: {
			display: 'none',
		},
	},
	mobileNav: {
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		}
	}
}));

export function MainNav() {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const closeDrawer = useCallback(() => setDrawerOpen(false), []);
	const styles = useStyles();

	return (
		<div>
			<Affix className={ styles.desktopNav } offsetTop={ 0 }>
				<div className={ styles.desktopNavContent }>
					<ForeNoraLogo className={ styles.iconDesktop } />

					<div>
						<Link className={ styles.link } to="/home">Home</Link>
						<Link className={ styles.link } to="/event">Event Details</Link>
						<Link className={ styles.link } to="/sponsor">Sponsorship</Link>
						<Link className={ styles.link } to="/nora">Nora's Story</Link>
					</div>

					<div>
						<Link className={ styles.link } to="/register">
							<Button type="primary">Register</Button>
						</Link>
						<Link className={ styles.link } to="/donate">
							<Button>Donate Now</Button>
						</Link>
					</div>
				</div>

			</Affix>

			<Affix className={ styles.mobileNav } offsetTop={ 0 }>
				<div className={ styles.mobileNavContent }>
					<ForeNoraLogo className={ styles.iconDesktop } />

					<div>
						<Button onClick={ () => setDrawerOpen(true) } icon={ <MenuOutlined /> } />
						<Drawer
							closable={ true }
							onClose={ closeDrawer }
							placement="right"
							visible={ drawerOpen }
						>
							<div className={ styles.mobileLinksContainer }>
								<Link onClick={ closeDrawer } className={ styles.mobileLink } to="/home">Home</Link>
								<Link onClick={ closeDrawer } className={ styles.mobileLink } to="/event">Event Details</Link>
								<Link onClick={ closeDrawer } className={ styles.mobileLink } to="/sponsor">Sponsorship</Link>
								<Link onClick={ closeDrawer } className={ styles.mobileLink } to="/nora">Nora's Story</Link>
								<Link onClick={ closeDrawer } className={ styles.mobileLink } to="/register">
									<Button type="primary">Register</Button>
								</Link>
								<Link onClick={ closeDrawer } className={ styles.mobileLink } to="/donate">
									<Button>Donate Now</Button>
								</Link>
							</div>
						</Drawer>
					</div>
				</div>
			</Affix>
		</div>
	)
}
