const portraitDimentions = {width: 3, height: 4};
const landscapeDimentions = {width: 4, height: 3};

export const photoObjects = [
    // Section 1
    {
        src: 'https://i.imgur.com/4ND9KBGl.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/jeD2ANsl.jpg',
        ...landscapeDimentions,
    },
    {
        src: 'https://i.imgur.com/B96W15yl.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/q3kiW3Wl.jpg',
        ...landscapeDimentions,
    },
    {
        src: 'https://i.imgur.com/7J7iR9Fl.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/VS1NPlDl.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/if8SQXul.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/Yhz3JQql.jpg',
        ...landscapeDimentions,
    },
    {
        src: 'https://i.imgur.com/n089gHLl.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/sQ0nETql.jpg',
        ...portraitDimentions,
    },
    // Section 2
    {
        src: 'https://i.imgur.com/SV0KcK6l.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/SR5wzeBl.jpg',
        ...landscapeDimentions,
    },
    {
        src: 'https://i.imgur.com/kKtP6NZl.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/fzGut4xl.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/7nlset5l.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/wTEDog3l.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/tpLjhLkl.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/kKluzepl.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/YYtgTdpl.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/DIaiL6Ll.jpg',
        ...landscapeDimentions,
    },
    // Section 3
    {
        src: 'https://i.imgur.com/MkkMeUJl.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/rT9Ow17l.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/1emmB33l.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/VoDCXmzl.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/dVGKxPLl.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/LacIMV4l.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/P1IOv0Sl.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/Oy6wJiwl.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/ZRVwmeMl.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/5PUweGll.jpg',
        ...portraitDimentions,
    },
    // Section 4
    {
        src: 'https://i.imgur.com/BLfjFJ4l.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/OagNKuUl.jpg',
        ...landscapeDimentions,
    },
    {
        src: 'https://i.imgur.com/swIbb8Zl.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/W5CKMEyl.jpg',
        ...landscapeDimentions,
    },
    {
        src: 'https://i.imgur.com/Vmzju3Pl.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/WSPtnV2l.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/ix6f0Uzl.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/hg9cqaul.jpg',
        ...landscapeDimentions,
    },
    // Section 5
    {
        src: 'https://i.imgur.com/zsKM4fRl.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/80139wDl.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/RAFNE5ul.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/5xuO5Bnl.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/8HzT8Tul.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/kYSPqMvl.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/PuYQnWwl.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/JvDX4Zyl.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/VOY1QFEl.jpg',
        ...landscapeDimentions,
    },
    // Section 6
    {
        src: 'https://i.imgur.com/5NdGjZMl.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/p5b51Cfl.jpg',
        ...landscapeDimentions,
    },
    {
        src: 'https://i.imgur.com/KcvBUJ2l.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/ZAMMjC5l.jpg',
        ...portraitDimentions,
    },
    {
        src: 'https://i.imgur.com/s8wR1lCl.jpg',
        ...landscapeDimentions,
    },
];