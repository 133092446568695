import {
	List,
} from 'antd';

const OPPORTUNITIES = [
	{
		header: <span>Title Sponsor - $7000</span>,
		description: 'Help us cover the cost of green fees, and receive the following benefits:',
		bulletPoints: [
			'Become the main sponsor of the event',
			'Have your logo on main event banners',
			'Your logo on the back of the event t-shirt',
			'Set up a tent/display at one of the tee boxes',
			'Includes 1 free foursome',
			'Collaborate with us on other ways to incorporate your logo into the event'
		]
	},
	{
		header: <span>Luncheon Sponsor - $2000</span>,
		description: `Help cover the cost of the luncehon, and receive the following benefits:`,
		bulletPoints: [
			'Signage in the banquet area indicating your sponsorship',
			'Your logo on the back of the event t-shirt'
		]
	},
	{
		header: 'Continental Breakfast Sponsor - $1500',
		description: `Help cover the cost of a continental breakfast for the players, and receive the following benefits:`,
		bulletPoints: [
			'Signage in the continental breakfast area indicating your sponsorship',
			'Your logo on the back of the event t-shirt'
		]
	},
	{
		header: <span>Snack Cart Sponsor - $1500</span>,
		description: `Help us provide snacks and drinks to the players, and receive the following benefits`,
		bulletPoints: [
			'Your logo prominently displayed on the snack cart',
			'Your logo on the back of the event t-shirt'
		]
	},
	{
		header: `Longest Drive Contest Sponsor - $1000`,
		description: `Help cover the cost of the prize for the "Longest Drive" contest, and receive the following benefit:`,
		bulletPoints: [
			'A sign at the contest hole indicating your sponsorship, and the option to set up a tent/display at the tee box',
			'Your logo on the back of the event t-shirt'
		]
	},
	{
		header: <span>Closest to the Pin Contest Sponsor - $1000</span>,
		description: `Help cover the cost of the prize for the "Closest to the Pin" contest, and receive the following benefit:`,
		bulletPoints: [
			'A sign at the contest hole indicating your sponsorship, and the option to set up a tent/display at the tee box',
			'Your logo on the back of the event t-shirt'
		]
	},
	{
		header: 'Hole Sponsor (5 remaining) - $1000',
		description: `Sponsor one of the holes, and receive the following benefits:`,
		bulletPoints: [
			'A sign at the contest hole indicating your sponsorship, and the option to set up a tent/display at the tee box',
			'Your logo on the back of the event t-shirt'
		]
	},
	{
		header: 'Hero Sponsor - $500',
		description: `Become a sponsor of the event, and receive the following benefits:`,
		bulletPoints: [
			'Logo placed on our "Hero Sponsors" sign that will be prominently displayed at registration as well as the banquet room',
			'Recognition as a Hero Sponsor during the luncheon'
		]
	}
]

export function Sponsor() {
	return (
		<>
			<h1>Event Sponsors</h1>
			<p>
				There are many opportunities to partner with the Nora Mae Budge Foundation as a sponsor of this event.
			</p>
			<p>
				<strong>
					All net proceeds will be donated to help children with congenital heart disease and their families at&nbsp;
					<a href="https://intermountainhealthcare.org/primary-childrens" target="_blank" rel="noreferrer">Primary Children's Hospital</a>
					&nbsp;through the&nbsp;
					<a href="https://intermountainhealthcare.org/giving-volunteering/intermountain-foundation/about-us/" target="_blank" rel="noreferrer">Intermountain Foundation</a>.
				</strong>
			</p>
			<p>
				Your sponsorship will help us make an even greater impact in the lives of these children!
			</p>

			<hr />

			<p>
				<strong>Note: </strong>
				All donations, including sponsorship donations, are handled by The Nora Mae Budge Foundation, which is a 501(c)(3) non-profit organization.
			</p>
			<p>
				Please send all sponsorship questions to <a href="mailto:info@forenora.com">info@forenora.com</a> — Thank you!
			</p>

			<List
				bordered={ true }
				dataSource={ OPPORTUNITIES }
				size="large"
				header={ <h2>Sponsorship Opportunities</h2> }
				itemLayout="vertical"
				renderItem={ ({header, description, bulletPoints}) => (
					<List.Item>
						<h3>{ header }</h3>
						<p>{ description }</p>
						{ bulletPoints && !!bulletPoints.length && (
							<ul>
							{ bulletPoints.map((bulletPoint, index) => (
								<li key={ index }>{ bulletPoint }</li>
							)) }
						</ul>
						) }
					</List.Item>
				) }
			/>
			<br />
			<h3>
				None of these options work? Send us a message at&nbsp;
				<a href="mailto:info@forenora.com">info@forenora.com</a>
				&nbsp;and let's figure something out! We would love to work with you.
			</h3>
		</>
	)
}
