import {
	BrowserRouter as Router,
	Switch,
	Route,
} from "react-router-dom";
import {
	makeStyles,
} from '@material-ui/core';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
	MainNav,
	Register,
	NorasStory,
	EventDetails,
	Home,
	Sponsor,
	Footer,
	Donate,
	PhotoGallery,
	ScrollToTop,
} from './components';

const useStyles = makeStyles({
	maxWidthContainer: {
		maxWidth: '1300px',
		margin: '0 auto',
		padding: '0 30px'
	},
	pageContentContainer: {
		padding: '30px 30px 100px',
	}
});

const STRIPE_PUBLIC_TEST = 'pk_test_51JCUyyHrl93bVL8WGs9Rpnewm3DOUx0RJaC77vuYHJ5iF0SGU6T8oOJhaRrcBa36E4F7EPz4SEhWfjze9BtwW76g006DE9S1tQ';
const STRIPE_PUBLIC_LIVE = 'pk_live_51JCUyyHrl93bVL8W40xdDTS4J58Ra5N93SN5tXGsubyRFJHryBDsouPzkIplRr4meECBRegs6e7BoYpKJcapS36J00fqiaiXt2';

const STRIPE_PROMISE = process.env.NODE_ENV === 'development' ? loadStripe(STRIPE_PUBLIC_TEST) : loadStripe(STRIPE_PUBLIC_LIVE);

export function ForeNora() {
	const styles = useStyles();

	return (
		<Router>
			<div className={ styles.maxWidthContainer }>
				<MainNav />
			</div>

			<ScrollToTop />

			<div className={ `${ styles.maxWidthContainer } ${ styles.pageContentContainer }` }>
				<Switch>
					<Route path="/nora">
						<NorasStory />
					</Route>
					<Route path="/register">
						<Elements stripe={STRIPE_PROMISE}>
							<Register />
						</Elements>
					</Route>
					<Route path="/sponsor">
						<Sponsor />
					</Route>
					<Route path="/event">
						<EventDetails />
					</Route>
					<Route path="/donate">
						<Donate />
					</Route>
					<Route path={['/', '/home']}>
						<Home />
					</Route>
				</Switch>
			</div>

			<div className={ styles.maxWidthContainer }>
				<PhotoGallery />
			</div>

			<div className={ styles.maxWidthContainer }>
				<Footer />
			</div>
		</Router>
	);
}
