import {
	makeStyles,
} from '@material-ui/core/styles';
import {
	Affix,
	Button,
} from 'antd';
import {
	InstagramFilled,
	MailFilled,
} from '@ant-design/icons';

const useStyles = makeStyles({
	container: {
		backgroundColor: '#fff',
		borderTop: '1px solid #9a9a9a',
		padding: '15px 0',
		textAlign: 'center',
	},
	instagramBtn: {
		marginRight: 10,
	}
});

export function Footer() {
	const styles = useStyles();
	return (
		<Affix offsetBottom={ 0 }>
			<div className={ styles.container }>
				<a href="https://www.instagram.com/forenoragolf" target="_blank" rel="noreferrer">
					<Button className={ styles.instagramBtn } size="small" icon={ <InstagramFilled /> }>@forenoragolf</Button>
				</a>

				<a href="mailto:info@forenora.com">
					<Button size="small" icon={ <MailFilled /> }>Email</Button>
				</a>
			</div>
		</Affix>
	)
}
